<template>
  <div class="message-overview">
    <input
      type="text"
      v-model="searchQuery"
      placeholder="Search..."
      class="search-bar"
    />
    <ul>
      <li
        v-for="conversation in filteredConversations"
        :key="conversation.id"
        @click="selectConversation(conversation)"
        :class="{ active: conversation.id === selectedConversationId }"
      >
        <div class="conversation-item">
          <img
            :src="
              getProfilePicture(
                conversation.otherUser.profileImage,
                conversation.otherUser.roleName
              )
            "
            alt="Profile"
            class="profile-picture"
          />
          <div class="conversation-details">
            <div class="username">{{ conversation.otherUser.username }}</div>
            <div class="message-preview">
              {{
                conversation.last_message_preview.length > 16
                  ? conversation.last_message_preview.substring(0, 16) + "..."
                  : conversation.last_message_preview || "No messages yet"
              }}
            </div>
            <div class="message-date">
              {{ formatDate(conversation.lastMessageAt) }}
            </div>
          </div>
          <div v-if="conversation.unread_count > 0" class="unread-indicator">
            {{ conversation.unread_count }}
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from "axios";
import eventBus from "@/utils/eventBus.js";

export default {
  data() {
    return {
      conversations: [],
      searchQuery: "",
      selectedConversationId: null,
      currentUserId: null,
      lastProcessedMessageTimestamp: null,
    };
  },
  computed: {
    filteredConversations() {
      return this.conversations.filter((conversation) =>
        conversation.otherUser.username
          .toLowerCase()
          .includes(this.searchQuery.toLowerCase())
      );
    },
  },
  async created() {
    await this.fetchUserProfile();
    await this.fetchConversations();
    this.loadConversationFromStorage();
    // Handle the query parameter here
    const userToMessage = this.$route.query.user;
    if (userToMessage) {
      await this.startConversationWithUser(userToMessage);
    }
    eventBus.on("message-sent", this.handleMessageSent);
    eventBus.on("messages-read", this.handleMessagesRead);
    eventBus.on("message-received", this.handleReceivedMessage);
  },
  beforeUnmount() {
    eventBus.off("message-sent", this.handleMessageSent);
    eventBus.off("messages-read", this.handleMessagesRead);
    eventBus.off("message-received", this.handleReceivedMessage);
    localStorage.removeItem("selectedConversationId");
  },

  methods: {
    async fetchUserProfile() {
      try {
        const response = await axios.get("/users/profile", {
          withCredentials: true,
        });
        this.currentUserId = response.data.user_id;
        this.userRole = response.data.role; // Make sure this is stored correctly

        // Log the fetched role and user ID
        console.log("Fetched user profile:", response.data);
        // console.log("Logged-in user role (userRole):", this.userRole);
        // console.log("Logged-in user ID (currentUserId):", this.currentUserId);

        // Emit the role if you need it in other components
        eventBus.emit("user-role", this.userRole);
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    },
    async fetchConversations() {
      try {
        const response = await axios.get("/conversations", {
          withCredentials: true,
        });
        this.conversations = response.data.map((conversation) => {
          const otherUser =
            conversation.user1Id === this.currentUserId
              ? conversation.user2
              : conversation.user1;

          // Ensure the role is included when setting up the conversation
          return {
            id: conversation.id,
            otherUser: {
              ...otherUser, // Spread operator to include all other fields
              role: otherUser.role || 1, // Default to consumer (role 1) if undefined
            },
            lastMessageAt: conversation.lastMessageAt,
            last_message_preview: conversation.last_message_preview,
            unread_count: conversation.unread_count || 0, // Adjust as needed
          };
        });
      } catch (error) {
        console.error("Error fetching conversations:", error);
      }
    },
    handleMessageSent(messageData) {
      const conversation = this.conversations.find(
        (convo) => convo.id === messageData.conversationId
      );

      if (conversation) {
        conversation.last_message_preview = messageData.content;
        conversation.lastMessageAt = new Date().toISOString();
      } else {
        console.log("Conversation not found, fetching conversations");
        this.fetchConversations(); // Optionally refetch if conversation isn't found
      }
    },
    handleReceivedMessage(messageData) {
      // Find the conversation for the message
      const conversation = this.conversations.find(
        (convo) => convo.id === messageData.conversationId
      );

      if (this.lastProcessedMessageTimestamp === messageData.createdAt) {
        // If the timestamp matches the last processed message, skip it
        return;
      }

      this.lastProcessedMessageTimestamp = messageData.createdAt;

      // Update the conversation preview
      if (conversation) {
        conversation.last_message_preview = messageData.content;
        conversation.lastMessageAt = new Date().toISOString();

        // Optionally, increment unread count if the conversation isn't selected
        if (conversation.id !== this.selectedConversationId) {
          // Ensure it only increments once for each new message
          // if (conversation.unread_count === 0 || messageData) {
          //   conversation.unread_count += 1;
          // }
          conversation.unread_count += 1;
        }
        this.$forceUpdate();
      } else {
        // Optionally fetch the conversation list again if needed
        this.fetchConversations();
      }
    },
    async startConversationWithUser(username) {
      try {
        const userResponse = await axios.get(`/users/${username}`, {
          withCredentials: true,
        });
        const userId = userResponse.data.id;

        const existingConversation = this.conversations.find(
          (convo) => convo.otherUser.username === username // Matching conversation by username
        );

        if (existingConversation) {
          this.selectConversation(existingConversation);
        } else {
          const newConversationResponse = await axios.post(
            "/conversations",
            {
              user1_id: this.currentUserId,
              user2_id: userId,
            },
            { withCredentials: true }
          );

          this.conversations.push(newConversationResponse.data);
          this.selectConversation(newConversationResponse.data);
        }
      } catch (error) {
        console.error("Error starting conversation:", error);
      }
    },
    selectConversation(conversation) {
      this.selectedConversationId = conversation.id; // Update selected conversation
      localStorage.setItem("selectedConversationId", conversation.id);
      this.$emit("select-conversation", conversation); // Emit to parent component
      this.markMessagesAsRead(conversation.id);
    },
    loadConversationFromStorage() {
      const storedConversationId = localStorage.getItem(
        "selectedConversationId"
      );
      if (storedConversationId) {
        const conversation = this.conversations.find(
          (convo) => convo.id === parseInt(storedConversationId, 10)
        );
        if (conversation) {
          this.selectConversation(conversation);
        }
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const today = new Date();

      // Check if the date is today
      if (
          date.getDate() === today.getDate() &&
          date.getMonth() === today.getMonth() &&
          date.getFullYear() === today.getFullYear()
      ) {
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      }

      // Check if the date is yesterday
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
      if (
          date.getDate() === yesterday.getDate() &&
          date.getMonth() === yesterday.getMonth() &&
          date.getFullYear() === yesterday.getFullYear()
      ) {
        return "Yesterday";
      }

      // If older than yesterday, format as dd. mmm (e.g., 25. Oct)
      return date.toLocaleDateString(undefined, {
        day: '2-digit',
        month: 'short'
      });
    },
    getProfilePicture(profileImage, role) {
      const creatorDefaultImage = require("@/assets/images/picreator.webp");
      const consumerDefaultImage = require("@/assets/images/piconsumer.webp");

      if (profileImage) {
        // Construct the full URL for the profile image
        const baseUrl = process.env.VUE_APP_BASE_URL || 'https://domsphere.com/api';
        return `${baseUrl}/uploads/profile_images/${profileImage}`;
      } else {
        // Return default images based on role
        return role === "creator" ? creatorDefaultImage : consumerDefaultImage;
      }
    },

    handleMessagesRead(conversationId) {
      const conversation = this.conversations.find(
        (c) => c.id === conversationId
      );
      if (conversation) {
        conversation.unread_count = 0;
      }
    },
    async markMessagesAsRead(conversationId) {
      try {
        await axios.post(`/conversations/${conversationId}/read`, null, {
          withCredentials: true,
        });
        this.handleMessagesRead(conversationId);
      } catch (error) {
        console.error("Error marking messages as read:", error);
      }
    },
  },
};
</script>
<style scoped>
.message-overview {
  width: 30%;
  height: calc(100vh - 90px);
  border-right: 1px solid rgba(61, 41, 70, 0.4);
  /* overflow-y: auto; */
  margin-left: 50px;
}

.search-bar {
  width: 86%;
  padding: 10px;
  margin-top: 20px;
  border: 1px solid rgba(61, 41, 70, 1);
  background-color: rgba(0, 0, 0, 0);
  color: var(--creator-name-color, white);
  font-family: "Roboto", sans-serif;
  border-radius: 50px;
}

.message-overview ul {
  list-style-type: none;
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  padding: 10px;

}

.message-overview li {
  height: 60px;
  display: flex;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  color: var(--message-overview-color);
  background-color: var(--message-overview-bg);
}

.message-overview li:hover {
  background-color: var(--message-overview-hover-bg);
}

.message-overview li.active {
  background-color: var(--message-overview-active-bg);
}

.conversation-item {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}

.profile-picture {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
  image-rendering: optimizeSpeed;
}

.conversation-details {
  margin-right: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensures even spacing */
  height: 100%; /* Matches li height */
}

.username {
  font-family: "Rouge Script", cursive;
  margin-right: auto;
  font-weight: 500;
  font-size: 20px; /* Reduced size to fit within 60px */
  line-height: 1.2; /* Tighter line height */
}

.message-preview {
  margin-right: auto;
  color: var(--message-preview-color);
  font-weight: 300;
  font-size: 14px; /* Slightly smaller for better fit */
  line-height: 1.2; /* Adjusted for tighter spacing */
}

.message-date {
  font-size: 12px;
  color: var(--message-date-color);
  font-weight: 300;
  margin: 0; /* Removed excess margins */
  padding-top: 2px; /* Optional: small padding if needed */
  align-self: flex-end; /* Aligns date to the bottom */
}

.unread-indicator {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: purple;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
@media (max-width: 768px) {
  .message-overview {
    width: 100%;
    height: 100%;
    margin-left: 0;
    border-right: none;

  }


  .search-bar {
    width: calc(100% - 40px);
    margin: 20px auto;
  }
}
@media (max-width: 1100px) {
  .message-overview {
    border-right: 1px solid rgba(61, 41, 70, 0.4);
    height: 100%;
    margin-left: 0;

  }

  .search-bar {
    width: calc(100% - 40px);
    margin: 20px auto;
  }
}
</style>

import { defineStore } from "pinia";
import axios from "axios";

export const useProfileStore = defineStore("profile", {
  state: () => ({
    profile: null,
    loading: false,
    error: null,
  }),
  actions: {
    async fetchProfile() {
      if (this.profile || this.loading) {
        return;
      }

      this.loading = true;
      try {
        // Make the request without manually adding Authorization headers
        const response = await axios.get("/users/profile", {
          withCredentials: true, // Ensure cookies (including access_token) are sent
        });
        this.profile = response.data;
        localStorage.setItem("profile", JSON.stringify(this.profile));
        this.error = null;
      } catch (error) {
        if (error.response && error.response.status === 403) {
          // Handle case where the user is not authenticated (403 Forbidden)
          this.resetProfile();
          // You could trigger an event or call a method to show the login modal here
          // For example:
          // eventBus.emit('show-login-modal');
        } else {
          this.error = error;
          console.error("Error loading profile:", error);
        }
      } finally {
        this.loading = false;
      }
    },

    async refreshProfile() {
      try {
        // Again, no need for Authorization header
        const response = await axios.get("/users/profile", {
          withCredentials: true, // Ensure cookies are sent with the request
        });
        this.profile = response.data;
        this.error = null;
      } catch (error) {
        if (error.response && error.response.status === 403) {
          this.resetProfile();
          // Optionally show login modal here as well
          // eventBus.emit('show-login-modal');
        } else {
          this.error = error;
          console.error("Error refreshing profile:", error);
        }
      }
    },

    updateProfileImage(newImageUrl) {
      if (this.profile) {
        this.profile.profile_image = newImageUrl;
      }
    },

    resetProfile() {
      this.profile = null;
      this.error = null;
    },
  },
});

<template lang="en">
  <div class="username-container">
    <div v-if="userName" class="top-text">{{ userName }}</div>        
  </div>
</template>

<script>
import { useProfileStore } from '@/store/profileStore'; // Import profileStore

export default {
  name: 'UserName',
  computed: {
    // Access the profile from the profile store
    profileStore() {
      return useProfileStore();
    },

    // Fetch the username from the profile store
    userName() {
      return this.profileStore.profile ? this.profileStore.profile.username : '';
    }
  },

  mounted() {
    // Fetch profile when the component is mounted if it's not already loaded
    if (!this.profileStore.profile) {
      this.profileStore.fetchProfile();
    }
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900&family=Rouge+Script&display=swap');

.username-container {
  color: var(--username-container-color);
  font-family: 'Rouge Script', cursive;
  font-size: 1.6rem;
  font-weight: 100;
}

</style>

<template lang="en">
  <div class="menu-container">
    <nav class="navbar">
      <ul>
        <li @click="navigateToHome">&nbsp;&nbsp;Home</li>
        <li @click="navigateAndClose('/Messages')">&nbsp;&nbsp;Messages</li>
        <li @click="navigateAndClose('/Subscriptions')">&nbsp;&nbsp;Subscriptions</li>
        <li @click="navigateAndClose('/settings')">&nbsp;&nbsp;Settings</li>
        <li @click="logOffUser">&nbsp;&nbsp;Log Off</li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { useProfileStore } from '@/store/profileStore';
import { computed, onMounted } from 'vue';
import axios from "axios";

export default {
  setup() {
    const router = useRouter();
    const profileStore = useProfileStore();

    // Fetch profile data if not already loaded
    onMounted(async () => {
      if (!profileStore.profile) {
        await profileStore.fetchProfile();
      }
    });

    // Check if the user is a creator (role 2)
    const isCreator = computed(() => profileStore.profile?.role === 2);

    const navigateToHome = async () => {
      const profile = profileStore.profile;
      if (profile?.role === 2) {
        router.push({ path: `/${profile.username}` });
      } else {
        router.push('/feed');
      }
    };

    const logOffUser = async () => {
      try {
        // Call the logout endpoint to invalidate the session or clear the HttpOnly cookie
        await axios.post('/logout', {}, {
          withCredentials: true, // Ensure the request includes the session cookie
        });

        // Optionally, reset the client-side state after the server has logged out the user
        profileStore.resetProfile();
        router.push('/'); // Redirect to the home or login page
      } catch (error) {
        console.error('Error logging out:', error);
      }
    };

    const navigateAndClose = (path) => {
      router.push(path);
    };

    return {
      navigateToHome,
      logOffUser,
      navigateAndClose,
      isCreator
    };
  }
};
</script>

<style scoped>
.menu-container {
  width: 100%;
}

.navbar {
  display: flex;
  font-size: 15px;
  width: 170px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  flex: 0 0 60%;
  right: 0px;
}

.navbar ul {
  cursor: pointer;
  list-style-type: none;
  padding: 0px;
  text-align: center;
  color: var(--navbar-ul-color);
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.navbar ul li {
  cursor: pointer;
  list-style-type: none;
  text-align: left;
  align-content: center;
  height: 50px;
  border-bottom: 1px solid rgba(131, 95, 139, 0.5);
  padding-left: 30px;
  padding-right: 30px;
  font-family: 'Roboto', sans-serif;
  margin-left: auto;
  margin-right: auto;
  transition: padding-left 0.6s ease; /* Transition property */
  max-width: 90px;
}

.navbar ul li:hover {
  cursor: pointer;
  list-style-type: none;
  text-align: left;
  align-content: center;
  height: 50px;
  border-bottom: 1px solid rgba(131, 95, 139, 0.5);
  padding-left: 35px;
  padding-right: 30px;
  font-family: 'Roboto', sans-serif;
  margin-left: auto;
  margin-right: auto;
  max-width: 90px;

}


.navbar ul li a {
  cursor: pointer;
  display: block;
  text-decoration: underline;
  color: black;
  border-right: 1px solid white;
  transition: background-color 0.5s ease, color 0.5s ease;
  margin-left: auto;
  margin-right: auto;
}



.navbar ul li:hover {
  cursor: pointer;
  background-color:rgba(97, 61, 105, 0.15);
  border-bottom: 1px solid rgba(131, 95, 139, 0.5);
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 768px) {
  .menu-container {
    width: 0px;
    height: 0px;
  }

  .navbar {
    display: flex;
    font-size: 15px;
    width: 0px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    flex: 0 0 0%;
    right: 0px;
  }

  .navbar ul {
    cursor: pointer;
    list-style-type: none;
    padding: 0px;
    text-align: center;
    color: var(--navbar-ul-color);
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }

  .navbar ul li {
    cursor: pointer;
    list-style-type: none;
    text-align: left;
    align-content: center;
    height: 50px;
    border-bottom: 1px solid rgba(131, 95, 139, 0.5);
    padding-left: 30px;
    padding-right: 30px;
    font-family: 'Roboto', sans-serif;
    margin-left: auto;
    margin-right: auto;
    transition: padding-left 0.6s ease; /* Transition property */
    max-width: 90px;
  }

  .navbar ul li:hover {
    cursor: pointer;
    list-style-type: none;
    text-align: left;
    align-content: center;
    height: 50px;
    border-bottom: 1px solid rgba(131, 95, 139, 0.5);
    padding-left: 35px;
    padding-right: 30px;
    font-family: 'Roboto', sans-serif;
    margin-left: auto;
    margin-right: auto;
    max-width: 90px;

  }
  .navbar ul li a {
    cursor: pointer;
    display: block;
    text-decoration: underline;
    color: black;
    border-right: 1px solid white;
    transition: background-color 0.5s ease, color 0.5s ease;
    margin-left: auto;
    margin-right: auto;
  }

  .navbar ul li:hover {
    cursor: pointer;
    background-color:rgba(97, 61, 105, 0.15);
    border-bottom: 1px solid rgba(131, 95, 139, 0.5);
    transition: background-color 0.3s ease, color 0.3s ease;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>

<!-- src/components/AudioRecorder.vue -->

<template>
  <div class="audio-recorder">
    <button @click="startRecording" v-if="!recording">
      <img src="../../assets/icons/inactiverecord.webp" alt="Start Recording" class="record-icon"/>
    </button>
    <button @click="stopRecording" v-if="recording">
      <img src="../../assets/icons/activerecord.webp" alt="Stop Recording" class="record-icon"/>
    </button>
  </div>
</template>

<script>
export default {
  name: 'AudioRecorder',
  data() {
    return {
      mediaRecorder: null,
      audioChunks: [],
      audioUrl: null,
      recording: false,
      stream: null,  // Save the stream reference to stop it later
    };
  },
  methods: {
    async startRecording() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({audio: true});
        this.mediaRecorder = new MediaRecorder(stream);

        this.mediaRecorder.ondataavailable = (event) => {
          this.audioChunks.push(event.data);
        };

        this.mediaRecorder.onstop = () => {
          const audioBlob = new Blob(this.audioChunks, {type: 'audio/wav'});
          this.audioUrl = URL.createObjectURL(audioBlob);
          this.$emit('audio-recorded', audioBlob); // Emit the recorded audio blob
          this.audioChunks = []; // Reset chunks after recording
        };

        this.mediaRecorder.start();
        this.recording = true;
      } catch (error) {
        console.error("Error accessing audio devices:", error);
      }
    },
    stopRecording() {
      if (this.mediaRecorder) {
        this.mediaRecorder.stop();
        this.recording = false;

        // Stop the media stream to release the microphone
        if (this.stream) {
          this.stream.getTracks().forEach(track => track.stop());
        }
      }
    },
    // Clean up method to stop the recording and the stream manually
    stopStream() {
      if (this.stream) {
        this.stream.getTracks().forEach(track => track.stop());
      }
    }
  },
};
</script>

<style scoped>
.record-icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-top: 4px;
}

button {
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
}

button:focus {
  outline: none;
}
</style>

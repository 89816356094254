import { ref } from 'vue';

const eventBus = {
  events: ref({}),

  on(event, callback) {
    if (!this.events.value[event]) {
      this.events.value[event] = [];
    }
    this.events.value[event].push(callback);
  },

  off(event, callback) {
    if (!this.events.value[event]) return;
    this.events.value[event] = this.events.value[event].filter(cb => cb !== callback);
  },

  emit(event, data) {
    if (!this.events.value[event]) return;
    this.events.value[event].forEach((callback) => {
      callback(data);
    });
  }
};

export default eventBus;
<template>
  <div class="left-container">
    <div class="content-wrapper">
      <AppLogo />
      <div class="navigation-container">
        <ProfileImage />
        <UserName :userName="userName" />
        <MenuBar :userProfile="userProfile" />
      </div>
    </div>
  </div>
</template>

<script>
import AppLogo from '@/components/navigation/AppLogo.vue';
import ProfileImage from '@/components/shared/ProfileImage.vue';
import UserName from '@/components/shared/UserName.vue';
import MenuBar from '@/components/navigation/MenuBar.vue';
import { useProfileStore } from '@/store/profileStore';
import eventBus from "@/utils/eventBus"; // Import the event bus

export default {
  name: 'LeftBar',
  components: {
    AppLogo,
    ProfileImage,
    UserName,
    MenuBar,
  },
  computed: {
    userProfile() {
      return this.profileStore.profile;
    },
    userName() {
      return this.profileStore.profile ? this.profileStore.profile.username : '';
    }
  },
  setup() {
    const profileStore = useProfileStore();
    return { profileStore };
  },
  mounted() {
    // Fetch profile if it's not already loaded
    if (!this.profileStore.profile) {
      this.profileStore.fetchProfile();
    }

    // Listen for the 'logged-in' event to refresh the profile
    eventBus.on('logged-in', () => {
      this.profileStore.fetchProfile(); // Fetch the profile again after login
    });

    // Listen for profile image updates
    eventBus.on('profile-image-updated', (newUrl) => {
      this.profileStore.profile.profile_image = newUrl;
    });
  },
  beforeUnmount() {
    // Clean up listeners to avoid memory leaks
    eventBus.off('logged-in');
    eventBus.off('profile-image-updated');
  },
};
</script>

<style scoped>
.left-container {
  min-width: 22%;
  height: 100vh;
  margin-left: 0;
}

.navigation-container {
  height: 500px;
  min-width: 150px;
  width: 180px;
  margin-left: auto;
  margin-right: 0;
  margin-top: 65px;
  border-radius: 10px;
  border: 1px solid rgba(175, 139, 182, 0.5);
  box-shadow: var(--navigation-container-box-shadow);
  background-color: var(--navigation-container-background);
}

.content-wrapper {
  width: 180px;
  position: absolute;
  left: 10.6%;
}

@media screen and (max-width: 1100px) {

  .left-container {



    margin-left: -7%;

  }
}
@media screen and (max-width: 1100px) {
  .left-container {
    width: 0;
    min-width: 0;
    height: 0;
    margin-left: 0;
    display: none;
  }
  .navigation-container {
    height: 500px;
    min-width: 150px;
    width: 180px;
    margin-left: auto;
    margin-right: 0;
    margin-top: 65px;
    border-radius: 10px;
    border: 1px solid rgba(175, 139, 182, 0.5);
    box-shadow: var(--navigation-container-box-shadow);
    background-color: var(--navigation-container-background);
  }
  .content-wrapper {
    width: 0;
    position: absolute;
    left: 10.6%;
    content-visibility: hidden;
  }

}
@media screen and (max-width: 768px) {
  .left-container {
    width: 0;
    min-width: 0;
    height: 0;
    margin-left: 0;
    display: none;
  }

  .navigation-container {
    height: 500px;
    min-width: 150px;
    width: 180px;
    margin-left: auto;
    margin-right: 0;
    margin-top: 65px;
    border-radius: 10px;
    border: 1px solid rgba(175, 139, 182, 0.5);
    box-shadow: var(--navigation-container-box-shadow);
    background-color: var(--navigation-container-background);
  }

  .content-wrapper {
    width: 0;
    position: absolute;
    left: 10.6%;
    content-visibility: hidden;
  }
}
</style>

<template lang="en">
  <div class="content">
    <div class="logo-container">
      <img alt="App logo" src="../../assets/logos/logosmall.webp" loading="lazy" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppLogo',
};
</script>
<style scoped>
.logo-container {
  position: relative;
  left: 15px;
  width: 150px;
  height: 150px;
  z-index: 9000; /* Set a high z-index to ensure it's always on top */
}

.logo-container img {
  width: 100%;
  height: auto;
  display: block;
  z-index: 9000;
}

.content {
  margin-top: -50px;
}
</style>
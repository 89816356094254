<template>
  <div class="set-new-password-container">
    <h3>Set New Password</h3>
    <form @submit.prevent="setNewPassword">
      <div class="form-group">
        <input type="password" v-model="password" placeholder="New Password" required />
      </div>
      <div class="form-group">
        <input type="password" v-model="confirmPassword" placeholder="Confirm Password" required />
      </div>
      <button type="submit">Set New Password</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'SetNewPassword',
  props: ['token'],
  data() {
    return {
      password: '',
      confirmPassword: '',
    };
  },
  mounted() {
    if (!this.token) {
      alert('Invalid password reset link.');
      this.$router.push({ name: 'login' });
    }
  },
  methods: {
    async setNewPassword() {
      if (this.password !== this.confirmPassword) {
        alert('Passwords do not match.');
        return;
      }
      try {
        await axios.post('/users/reset-password', {
          token: this.token,
          newPassword: this.password,
        });
        alert('Password reset successful.');
        this.$router.push({ name: 'login' });
      } catch (error) {
        console.error(error);
        alert('Error resetting password. Please try again.');
      }
    },
  },
};
</script>

<style scoped>
.set-new-password-container h3 {

    font-weight: 100;
    font-family: Roboto, sans-serif;
  margin-bottom: 32px;

}

.form-group {
  margin-bottom: 15px;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  text-rendering: optimizeLegibility;
}

.form-group input {
  width: 100%;
  background-color: rgba(217, 217, 217, 1);
  padding: 16px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 50px;
  height: 40px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 0px;
}

button {
  width: 70%;
  border-radius: 50px;
  text-rendering: optimizeLegibility;
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: rgba(97, 61, 105, 0.5);
  border: none;
  cursor: pointer;
  height: 40px;
  margin: 85px auto 10px;
}

button:disabled {
  background-color: rgba(142, 100, 149, 1);
  cursor: not-allowed;
}

@media screen and (max-width: 768px) {

  button {
    width: 70%;
    border-radius: 50px;
    text-rendering: optimizeLegibility;
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: rgba(97, 61, 105, 0.5);
    border: none;
    cursor: pointer;
    height: 40px;
    margin: 50px auto 10px;
  }
  button:disabled {
    background-color: rgba(142, 100, 149, 1);
    cursor: not-allowed;
  }


}
</style>
import { createRouter, createWebHistory } from "vue-router";
import LoginSite from "@/components/auth/LoginSite.vue";
import SignupPage from "@/components/auth/SignupPage.vue";
import LayoutPage from "@/components/layout/LayoutPage.vue";
import MessagesContainer from "@/components/messages/MessagesContainer.vue";

const ConsumerFeed = () => import("@/components/feed/ConsumerFeed.vue");
const CreatorFeed = () => import("@/components/feed/CreatorFeed.vue");
const SubscriptionPage = () =>
  import("@/components/subscription/SubscriptionPage.vue");
const SettingsPage = () => import("@/components/settings/SettingsPage.vue");
const NotFoundError = () => import("@/components/errors/NotFoundError.vue");
const ServerError = () => import("@/components/errors/ServerError.vue");
const BadRequestError = () => import("@/components/errors/BadRequestError.vue");
const UnauthorizedError = () =>
  import("@/components/errors/UnauthorizedError.vue");
const ForbiddenError = () => import("@/components/errors/ForbiddenError.vue");
const ServiceUnavailableError = () =>
  import("@/components/errors/ServiceUnavailableError.vue");

import RequestPasswordReset from "@/components/auth/RequestPasswordReset.vue";

const routes = [
  { path: "/", name: "login", component: LoginSite },
  { path: "/signup", name: "signup", component: SignupPage },
  {
    path: "/forgot-password",
    name: "requestPasswordReset",
    component: RequestPasswordReset,
  },
  { path: "/reset-password", name: "setNewPassword", component: LoginSite },
  {
    path: "/",
    component: LayoutPage,
    children: [
      { path: "Feed", name: "Feed", component: ConsumerFeed },
      {
        path: "Subscriptions",
        name: "subscriptionPage",
        component: SubscriptionPage,
      },
      { path: ":id", name: "creatorFeed", component: CreatorFeed },
      { path: "Settings", components: { rightbar: SettingsPage } },
      { path: "Messages", components: { rightbar: MessagesContainer } },
      { path: "400", name: "badRequest", component: BadRequestError },
      { path: "401", name: "unauthorized", component: UnauthorizedError },
      { path: "403", name: "forbidden", component: ForbiddenError },
      { path: "404", name: "notFound", component: NotFoundError },
      { path: "500", name: "serverError", component: ServerError },
      {
        path: "503",
        name: "serviceUnavailable",
        component: ServiceUnavailableError,
      },
    ],
  },
  // Catch-all route for 404 errors
  { path: "/:pathMatch(.*)*", redirect: { name: "notFound" } },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Navigation guard for error handling
router.beforeEach((to, from, next) => {
  const errorRoutes = [
    "badRequest",
    "unauthorized",
    "forbidden",
    "notFound",
    "serverError",
    "serviceUnavailable",
  ];
  if (errorRoutes.includes(to.name)) {
    // If it's already an error route, proceed
    next();
  } else if (!to.matched.length) {
    // If no route matched, redirect to 404
    next({ name: "notFound" });
  } else {
    // Otherwise, proceed as normal
    next();
  }
});

export default router;

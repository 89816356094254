<template>
  <div class="maincontainer">
    <div class="logincontainer">
      <img alt="App logo" src="../../assets/logos/logosmall.webp" width="150" />
      <h2>DomSphere</h2>
      <div>
        <FormInput
            v-if="currentView === 'login'"
            :fields="fields"
            button-label="Login"
            :on-submit="login"
        />
        <SignupPage
            v-else-if="currentView === 'signup'"
            @toggle-form="toggleForm"
        />
        <RequestPasswordReset
            v-else-if="currentView === 'requestPasswordReset'"
            @toggle-form="toggleForm"
        />
        <SetNewPassword
            v-else-if="currentView === 'setNewPassword'"
            :token="resetToken"
            @toggle-form="toggleForm"
        />
      </div>
      <router-link v-if="currentView === 'login'" class="router-link" @click.prevent="toggleForm('signup')" to="">Sign Up here</router-link>
      <router-link v-if="currentView === 'login'" class="router-link" @click.prevent="toggleForm('requestPasswordReset')" to="">Forgot Password</router-link>
      <router-link v-if="currentView !== 'login'" class="router-link" @click.prevent="toggleForm('login')" to="">Back to Login</router-link>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import FormInput from "@/components/auth/FormInput.vue";
import SignupPage from "@/components/auth/SignupPage.vue";
import RequestPasswordReset from "@/components/auth/RequestPasswordReset.vue";
import SetNewPassword from "@/components/auth/SetNewPassword.vue";

export default {
  name: "LoginSite",
  components: {
    FormInput,
    SignupPage,
    RequestPasswordReset,
    SetNewPassword,
  },
  data() {
    return {
      currentView: 'login',
      resetToken: '',
      fields: [
        { label: "Username or Email:", type: "text", model: "", id: "identifier", required: true },
        { label: "Password:", type: "password", model: "", id: "password", required: true }
      ]
    };
  },
  mounted() {
    // Check if there's a reset token in the URL
    const token = this.$route.query.token;
    if (token) {
      this.resetToken = token;
      this.currentView = 'setNewPassword';
    }
  },
  methods: {
    async login(formData) {
      try {
        const response = await axios.post("/users/login", {
          identifier: formData.identifier,
          password: formData.password
        }, {
          withCredentials: true
        });

        console.log("User logged in successfully:", response.data.user);

        // Redirect based on user role
        const user = response.data.user;
        if (user.role === 'creator') {
          this.$router.push({ path: `/${user.username}` });
        } else if (user.role === 'consumer') {
          this.$router.push({ path: '/feed' });
        } else if (user.role === 'admin') {
          this.$router.push({ path: '/admin' });
        } else {
          console.error('Unknown user role');
          this.$router.push({ path: '/' });
        }
      } catch (error) {
        if (error.response) {
          console.error("Login failed:", error.response.data);
          alert(`Login failed: ${error.response.data.error}`);
        } else {
          console.error("Login failed:", error.message);
          alert("Login failed. Please try again.");
        }
      }
    },
    toggleForm(view) {
      this.currentView = view;
    },
  }
};
</script>

<style scoped>
.maincontainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-rendering: optimizeLegibility;
  padding-top: 0; /* places the login-form 60 px from the top */
}

.logincontainer {
  width: 450px;
  height: 650px;
  margin: auto;
  padding-top: 20px;
  background: var(--logincontainer-background);
  border-radius: 2.5rem;
  box-shadow: var(--box-shadow);
  text-align: center;
}

.logincontainer h2 {
  font-family: 'Rouge Script', cursive;
  font-weight: 100;
  font-size: 2.5rem;
  color: #8e6495; /* 8e6495 or AD9673*/
  margin: 20px 0 40px 0;
}

.logincontainer img {
  margin-top: 30px;

}

.router-link {
  width: 100%;
  text-decoration: none;
  color: rgba(180, 139, 187, 1);
  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
  font-size: 14px;
  text-rendering: optimizeLegibility;

}

@media screen and (max-width: 768px) {
  .logincontainer {
    width: 100%;
    height: 100%;
    padding-top: 20%;
    background: var(--logincontainer-background);
    border-radius: 0rem;
    box-shadow: var(--box-shadow);
    text-align: center;
  }
  .logincontainer img {
    margin-top: 2%;
    width: 35%;
  }
  .logincontainer h2 {
    font-family: 'Rouge Script', cursive;
    font-weight: 100;
    font-size: 2.5rem;
    color: #AD9673;
    margin: 0px 0 20px 0;
  }
}
</style>
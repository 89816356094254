<template>
  <div class="profile-image-container" :style="profileImageStyle">
    <img v-if="resolvedProfileImageUrl" :src="resolvedProfileImageUrl" alt="Profile Image" class="profile-photo" loading="lazy" />
  </div>
</template>

// src/components/containers/ProfileImage.vue
<script>
import { computed, onMounted, onUnmounted } from 'vue';
import { useProfileStore } from '@/store/profileStore';
import eventBus from '@/utils/eventBus';

export default {
  name: 'ProfileImage',
  setup() {
    const profileStore = useProfileStore();
    const resolvedProfileImageUrl = computed(() => {

      if (profileStore.profile && profileStore.profile.profile_image) {
        return `${profileStore.profile.profile_image}`;
      }

      if (profileStore.profile && profileStore.profile.role === 2) {
        return require('@/assets/images/picreator.webp');
      }

      return require('@/assets/images/piconsumer.webp');
    });

    const profileImageStyle = computed(() => {
      return profileStore.profile && profileStore.profile.profile_image
          ? {}
          : {
            backgroundColor: '#FAF9F6',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100px',
            height: '100px',
            borderRadius: '50%',
          };
    });

    const updateProfileImage = (newImageUrl) => {
      profileStore.updateProfileImage(newImageUrl);
    };

    onMounted(() => {
      if (!profileStore.profile) {
        profileStore.fetchProfile();
      }
      eventBus.on('profile-image-updated', updateProfileImage);
    });

    onUnmounted(() => {
      eventBus.off('profile-image-updated', updateProfileImage);
    });

    return {
      resolvedProfileImageUrl,
      profileImageStyle,
    };
  },
};
</script>

<style scoped>
.profile-image-container {
  margin-top: 25px;
  margin-bottom: 25px;
  width: 100px;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}

.profile-photo {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
  object-position: center;
}
</style>
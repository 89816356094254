<template>
  <div class="message-chat" style="height: 100dvh">
    <div class="chat-header">
      <img
        class="BackButton"
        @click="backToOverview"
        :src="BackIcon"
        alt="Back Icon"
      />
      <div class="NameOfMessage">
        <router-link
          :to="{ name: 'creatorFeed', params: { id: creatorName } }"
          class="creator-name"
        >
          {{ selectedConversation.otherUser.username }}
        </router-link>
      </div>
    </div>

    <div class="chat-body">
      <ChatMessages
        v-if="selectedConversation.id"
        class="chat-messages"
        ref="chatMessages"
        :conversationId="selectedConversation.id"
        :userId="profileStore.profile?.user_id"
      />

      <!-- Typing Indicator -->
      <div v-if="isTyping" class="typing-indicator">
        Someone is typing...{{ console.log("Someone is typing") }}
      </div>

      <div v-if="isUploading" class="upload-progress">
        <p>Uploading... {{ progress }}%</p>
        <progress :value="progress" max="100"></progress>
      </div>
    </div>

    <!-- Media Preview Section -->
    <div v-if="file || audioBlob" class="media-preview">
      <div v-if="isImage(file)" class="media-preview-item">
        <img :src="filePreviewUrl" alt="Image Preview" class="preview-image" />
        <button @click="removeAttachment" class="remove-btn">&times;</button>
      </div>
      <div v-else-if="isVideo(file)" class="media-preview-item">
        <video :src="filePreviewUrl" controls class="preview-video"></video>
        <button @click="removeAttachment" class="remove-btn">&times;</button>
      </div>
      <div v-else-if="isAudio(file)" class="media-preview-item">
        <audio :src="filePreviewUrl" controls class="preview-audio"></audio>
        <button @click="removeAttachment" class="remove-btn">&times;</button>
      </div>
      <div v-if="audioBlob" class="media-preview-item">
        <audio :src="audioUrl" controls class="preview-audio"></audio>
        <button @click="removeAudioRecording" class="remove-btn">
          &times;
        </button>
      </div>
    </div>

    <div v-if="canSendMessage" class="chat-input">
      <div class="chatholder">
        <button @click="triggerFileInput">
          <img src="@/assets/icons/upload-icon.png" alt="Upload" />
        </button>
        <div class="audio-recorder-wrapper">
          <AudioRecorder @audio-recorded="handleAudioRecorded" />
        </div>
        <!-- <textarea
          v-model="newMessage"
          placeholder="Type a message..."
          @input="adjustTextareaHeight"
        ></textarea> -->
        <textarea
          v-model="newMessage"
          placeholder="Type a message..."
          @input="onMessageInput"
        ></textarea>
        <input
          type="file"
          ref="fileInput"
          style="display: none"
          @change="handleFileUpload"
        />
        <button
          @click="sendMessage"
          :disabled="!newMessage.trim() && !file && !audioBlob"
        >
          <img src="@/assets/icons/SendMessage.svg" alt="Send Message" />
        </button>
      </div>
    </div>
    <div v-else class="chat-input locked">
      <div class="locked-message">{{ lockedMessage }}</div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useRouter } from "vue-router";
import ChatMessages from "./ChatMessages.vue";
import AudioRecorder from "@/components/shared/AudioRecorder.vue";
import eventBus from "@/utils/eventBus.js";
import BackIcon from "@/assets/icons/back-svgrepo-com.svg";
import { useProfileStore } from "@/store/profileStore"; // Import the store
import SockJS from "sockjs-client";
import Stomp from "webstomp-client";

export default {
  components: { ChatMessages, AudioRecorder },
  props: { selectedConversation: Object },
  setup() {
    const router = useRouter();
    const profileStore = useProfileStore(); // Use the profile store

    // Fetch the profile once component is mounted
    profileStore.fetchProfile();

    return { router, profileStore };
  },
  data() {
    return {
      conversations: [],
      newMessage: "",
      file: null,
      filePreviewUrl: "",
      audioBlob: null,
      audioUrl: null,
      isSubscribed: false,
      isLoading: true,
      creatorName: "",
      BackIcon: BackIcon,
      stompClient: null,
      isWebSocketConnected: false,
      isTyping: false, // Flag to track if the other user is typing
      typingUser: "", // Name of the user typing
      typingTimeout: null, // Timeout for typing
      progress: 0, // Upload progress (percentage)
      isUploading: false, // To track if uploading is in progress
    };
  },
  computed: {
    userRole() {
      return this.profileStore.profile?.role || null; // Get the role from profile store
    },
    canSendMessage() {
      return !this.isLoading && this.isSubscribed;
    },
    lockedMessage() {
      return this.isCreator
        ? `${this.selectedConversation.otherUser.username} is not subscribed to you`
        : `Subscribe to ${this.selectedConversation.otherUser.username} to send messages`;
    },
    isCreator() {
      return this.userRole === 2; // Check if the user is a creator
    },
  },
  watch: {
    selectedConversation: {
      immediate: true,
      handler(newValue) {
        if (newValue && newValue.id) {
          this.creatorName = newValue.otherUser.username;
          localStorage.setItem("selectedConversationId", newValue.id);
          this.subscribeToConversation(newValue.id);
          this.checkSubscriptionStatus();
          this.markMessagesAsRead();
        } else {
          this.creatorName = "";
        }
      },
    },
  },

  methods: {
    async checkSubscriptionStatus() {
      this.isLoading = true;
      try {
        let endpoint = this.isCreator
          ? `/subscriptions/check/${this.selectedConversation.otherUser.username}/to-creator`
          : `/subscriptions/check/${this.selectedConversation.otherUser.username}`;

        const response = await axios.get(endpoint, { withCredentials: true });
        this.isSubscribed = response.data.is_subscribed;
      } finally {
        this.isLoading = false;
      }
    },
    connectWebSocket() {
      if (this.isWebSocketConnected) return;
      const socketUrl = `https://domsphere.com/api/ws`;
      const socket = new SockJS(socketUrl, null, {
        transports: ['websocket', 'xhr-streaming', 'xhr-polling'],
        heartbeatTimeout: 25000,
      });

      this.stompClient = Stomp.over(socket);
      this.stompClient.connect(
        {},
        (frame) => {
          console.log("Connected to WebSocket:", frame);
          // this.isWebSocketConnected = true;
          this.subscribeToAllConversations();
          this.subscribeToConversation();
        },
        (error) => {
          console.error("WebSocket connection error:", error);
        }
      );
    },

    subscribeToConversation() {
      if (this.stompClient && this.selectedConversation) {
        this.stompClient.subscribe(
          `/topic/conversation/${this.selectedConversation.id}`,
          (message) => {
            const receivedMessage = JSON.parse(message.body);

            // console.log("typing event", message);

            // if (receivedMessage.type === "TYPING") {
            //   this.typingUser = "Someone";
            //   this.isTyping = true;
            // } else if (receivedMessage.type === "STOP_TYPING") {
            //   this.isTyping = false;
            // }

            this.handleReceivedMessage(receivedMessage);
          }
        );
      }
    },

    subscribeToAllConversations() {
      // Ensure conversations array exists
      if (this.conversations && this.conversations.length > 0) {
        this.conversations.forEach((conversation) => {
          this.stompClient.subscribe(
            `/topic/conversation/${conversation.id}`,
            (message) => {
              const receivedMessage = JSON.parse(message.body);
              console.log("Message received via WebSocket:", receivedMessage);

              // Handle the received message globally (Update UI immediately)
              this.handleReceivedMessage(receivedMessage);
            }
          );
        });
      } else {
        console.error("No conversations available to subscribe to.");
      }
    },

    handleReceivedMessage(message) {
      if (this.$refs.chatMessages) {
        this.$refs.chatMessages.updateMessages(message);
      }
      eventBus.emit("message-sent", message);
      eventBus.emit("message-received", message);
    },

    // onMessageInput() {
    //   // Send typing event
    //   if (this.stompClient && this.stompClient.connected) {
    //     this.stompClient.send(
    //       "/app/typing",
    //       JSON.stringify({
    //         userId: this.profileStore.profile?.user_id,
    //         conversationId: this.selectedConversation.id,
    //         username: this.profileStore.profile?.username,
    //         type: "TYPING",
    //       }),
    //       {}
    //     );
    //   }

    //   // Clear previous typing timeout
    //   clearTimeout(this.typingTimeout);

    //   // Set a timeout to stop typing after 3 seconds of no input
    //   this.typingTimeout = setTimeout(() => {
    //     if (this.stompClient && this.stompClient.connected) {
    //       this.stompClient.send(
    //         "/app/typing",
    //         JSON.stringify({
    //           userId: this.profileStore.profile?.user_id,
    //           conversationId: this.selectedConversation.id,
    //           username: this.profileStore.profile?.username,
    //           type: "STOP_TYPING",
    //         }),
    //         {}
    //       );
    //     }
    //   }, 3000); // Stop typing after 3 seconds of inactivity
    // },

    disconnectWebSocket() {
      if (this.stompClient && this.stompClient.connected) {
        this.stompClient.disconnect();
      }
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
      if (this.file) {
        this.filePreviewUrl = URL.createObjectURL(this.file);
      }
    },
    removeAttachment() {
      this.file = null;
      this.filePreviewUrl = "";
      this.$refs.fileInput.value = "";
    },
    removeAudioRecording() {
      this.audioBlob = null;
      this.audioUrl = "";
    },
    handleAudioRecorded(audioBlob) {
      this.audioBlob = audioBlob;
      this.audioUrl = URL.createObjectURL(audioBlob);
    },
    isImage(file) {
      return file && file.type.startsWith("image/");
    },
    isVideo(file) {
      return file && file.type.startsWith("video/");
    },
    isAudio(file) {
      return file && file.type.startsWith("audio/");
    },
    async sendMessage() {
      if (
        !this.canSendMessage ||
        (!this.newMessage.trim() && !this.file && !this.audioBlob)
      )
        return;

      const messageData = {
        conversationId: this.selectedConversation.id,
        content: this.newMessage,
        senderId: this.profileStore.profile.user_id,
      };

      if (this.file || this.audioBlob) {
        const formData = new FormData();

        if (this.file) {
          formData.append("description", "description");
          formData.append("files", this.file);
          formData.append(`filenames[0]`, this.file.name);
          formData.append(`fileSizes[0]`, this.file.size);
          formData.append("mediaType", "chat");
        }
        if (this.audioBlob) {
          formData.append("description", "Audio recording");
          formData.append("files", this.audioBlob, "audioRecording.webm"); // Name it properly
          formData.append("filenames[0]", "audioRecording.webm");
          formData.append("fileSizes[0]", this.audioBlob.size);
          formData.append("mediaType", "chat");
        }
        try {
          this.progress = 0;
          this.isUploading = true;
          // Send file to the FileController
          const uploadResponse = await axios.post("/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
              this.progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              console.log("Upload progress: ", this.progress + "%");
            },
            withCredentials: true,
          });
          const fileUrl = uploadResponse.data;
          this.filePreviewUrl = fileUrl.uploadedFiles[0].basicUrl;
          this.isUploading = false;
          console.log("success!", fileUrl.uploadedFiles[0]);

          messageData.attachmentUrl = fileUrl.uploadedFiles[0].basicUrl;
        } catch (error) {
          console.error("File upload failed:", error);
          this.isUploading = false;
          return; // Exit if file upload fails
        }
      }

      try {
        // Broadcast the message via WebSocket
        if (this.stompClient && this.stompClient.connected) {
          this.stompClient.send(
            `/app/chat.sendMessage`,
            JSON.stringify(messageData),
            {}
          );
        }

        // // Update the local message list
        // if (this.$refs.chatMessages) {
        //   this.$refs.chatMessages.updateMessages(messageData);
        // }

        eventBus.emit("message-sent", messageData);
        this.resetMessageInputs();
      } catch (error) {
        console.error("Error sending message:", error);
        eventBus.emit("message-send-error", error.message);
      }
    },
    resetMessageInputs() {
      this.newMessage = "";
      this.file = null;
      this.filePreviewUrl = "";
      this.audioBlob = null;
      this.audioUrl = "";
      if (this.$refs.fileInput) this.$refs.fileInput.value = "";
    },
    backToOverview() {
      this.$emit("back-to-overview");
    },
    adjustTextareaHeight(event) {
      const textarea = event.target;
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    },
    async markMessagesAsRead() {
      if (this.selectedConversation && this.selectedConversation.id) {
        try {
          await axios.post(
            `/conversations/${this.selectedConversation.id}/read`,
            null,
            { withCredentials: true }
          );
          if (this.$refs.chatMessages)
            this.$refs.chatMessages.updateMessagesReadStatus();
          eventBus.emit("messages-read", this.selectedConversation.id);
        } catch (error) {
          console.error("Error marking messages as read:", error);
        }
      }
    },
    setupMessageListener() {
      eventBus.on("new-message", this.handleNewMessage);
    },
    handleNewMessage(message) {
      if (message.conversation_id === this.selectedConversation.id) {
        this.markMessagesAsRead();
      }
    },
  },
  mounted() {
    this.connectWebSocket();
    this.profileStore.fetchProfile();
  },
  beforeUnmount() {
    this.disconnectWebSocket();
    eventBus.off("new-message", this.handleNewMessage);
    if (this.stompClient) {
      this.stompClient.disconnect(() => {
        console.log("WebSocket disconnected");
      });
    }
  },
};
</script>

<style scoped>
.NameOfMessage {
  padding-right: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #3d2946;
  text-align: center;
}

.chat-input.locked {
  background-color: rgba(61, 41, 70, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  border-top: 1px solid rgba(61, 41, 70, 0.4);
}

.locked-message {
  color: var(--chatinput-color);
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  text-align: center;
}

.message-chat {
  display: flex;
  flex-direction: column;
  height: calc(100% - 20px);
  overflow: hidden;
  max-width: 70%;
  min-width: 100%;
}

.chat-header {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  padding-left: 10px;
  border-bottom: 1px solid rgba(61, 41, 70, 0.4);
  z-index: 1;
}

.BackButton {
  width: 0;
  margin-right: 0px;
  cursor: pointer;
  float: left;
  height: 70px;
  transition: width 0.3s ease, border-radius 0.3s ease;
  overflow: hidden;
  position: relative;
  display: flex;
  filter: brightness(0) saturate(100%) invert(16%) sepia(5%) saturate(5050%)
    hue-rotate(237deg) brightness(97%) contrast(92%);
}

.creator-name {
  color: var(--topbar-h1-color);
  font-family: "Rouge Script", cursive;
  font-size: 30px;
  font-weight: 200;
  text-decoration: none;
}

.chat-body {
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: calc(100% - 60px);
  overflow-y: auto;
}

.chat-messages {
  flex-grow: 1;
  overflow-y: auto;
}

.chatholder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  border: 1px solid rgba(61, 41, 70, 1);
  width: 100%;
  box-sizing: border-box;
}

.chat-input img {
  width: 20px;
  height: 20px;
  color: white;
}

.chat-input button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  filter: var(--chat-icons-color);
}

.chat-input button img {
  width: 20px;
  height: 20px;
  padding: 0 10px;
}

.audio-recorder-wrapper img {
  width: 20px; /* Same size as your icons */
  height: 20px;
}

.chat-input textarea {
  flex-grow: 1;
  resize: none;
  min-height: 30px;
  max-height: 30px;
  border: 1px solid rgba(61, 41, 70, 0);
  border-radius: 20px;
  background-color: transparent;
  color: var(--chatinput-color);
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  margin-left: 10px;
  padding: 3px 10px;
  width: 100%;
  box-sizing: border-box;
  outline: none;
}

.chat-input {
  display: flex;
  align-items: center;
  padding: 5px;
  border-top: 1px solid rgba(61, 41, 70, 0.4);
}

.chat-input button:hover {
  background-color: lightgrey;
  border-radius: 20px;
}

.media-preview {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.media-preview-item {
  position: relative;
  margin-right: 10px;
}

.preview-image,
.preview-video {
  max-width: 100px;
  max-height: 100px;
  border-radius: 5px;
}

.preview-audio {
  max-width: 300px;
  max-height: 100px;
  border-radius: 5px;
}

.remove-btn {
  position: absolute;
  top: 0;
  right: 0;
  background-color: black;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.remove-btn:hover {
  background-color: darkred;
}

@media (max-width: 1100px) {
  .message-chat {
    max-width: 100%;
    font-size: 16px;
  }

  .chat-header {
    position: sticky;
    top: 0;
  }

  .chat-input {
    position: sticky;
    bottom: 0;
    font-size: 16px;
  }

  .BackButton {
    width: 40px;
    margin-right: 10px;
    cursor: pointer;
    float: left;
    height: 70px;
    transition: width 0.3s ease, border-radius 0.3s ease;
    overflow: hidden;
    position: relative;
    display: flex;
    filter: brightness(0) saturate(100%) invert(16%) sepia(5%) saturate(5050%)
      hue-rotate(237deg) brightness(97%) contrast(92%);
  }
}

@media (max-width: 768px) {
  .message-chat {
    max-width: 100%;
    font-size: 16px;
  }

  .chat-header {
    position: sticky;
    top: 0;
  }

  .chat-input {
    position: sticky;
    bottom: 0;
    font-size: 16px;
  }

  .BackButton {
    width: 40px;
    margin-right: 10px;
    cursor: pointer;
    float: left;
    height: 70px;
    transition: width 0.3s ease, border-radius 0.3s ease;
    overflow: hidden;
    position: relative;
    display: flex;
    filter: brightness(0) saturate(100%) invert(16%) sepia(5%) saturate(5050%)
      hue-rotate(237deg) brightness(97%) contrast(92%);
  }

  .typing-indicator {
    font-style: italic;
    color: #888;
    padding: 5px 0;
  }

  .upload-progress {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }

  .upload-progress p {
    margin: 0;
    font-size: 14px;
    color: #666;
  }

  progress {
    width: 100%;
    height: 10px;
    border-radius: 5px;
    overflow: hidden;
  }
}
</style>

<template>
  <div class="request-password-reset-container">
    <h3>Forgot Password</h3>
    <form @submit.prevent="requestPasswordReset">
      <div class="form-group">
        <input type="email" id="email" placeholder="Email" v-model="email" required>
      </div>
      <button type="submit" :disabled="!isValidEmail">Request Password Reset</button>
    </form>
    <p v-if="message" :class="{ 'success-message': isSuccess, 'error-message': !isSuccess }">
      {{ message }}
    </p>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'RequestPasswordReset',
  data() {
    return {
      email: '',
      message: '',
      isSuccess: false
    };
  },
  computed: {
    isValidEmail() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(this.email);
    }
  },
  methods: {
    async requestPasswordReset() {
      try {
        const response = await axios.post('/users/forgot-password', { email: this.email });
        this.message = response.data.message;
        this.isSuccess = true;
      } catch (error) {
        this.message = error.response?.data?.error || 'An error occurred. Please try again.';
        this.isSuccess = false;
      }
    }
  }
};
</script>

<style scoped>

.request-password-reset-container h3{
  font-weight: 100;
  font-family: Roboto, sans-serif;

 }

.form-group {
  margin-bottom: 15px;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  text-rendering: optimizeLegibility;
}

.form-group input {
  width: 100%;
  background-color: rgba(217, 217, 217, 1);
  padding: 16px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 50px;
  height: 40px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 14px;
}

button {
  width: 70%;
  border-radius: 50px;
  text-rendering: optimizeLegibility;
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: rgba(97, 61, 105, 0.5);
  border: none;
  cursor: pointer;
  height: 40px;
  margin: 140px auto 10px;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.success-message {
  color: green;
}

.error-message {
  color: red;
}
@media screen and (max-width: 768px) {

  .request-password-reset-container{
    margin-top: -1px;

  }

  button {
    width: 70%;
    margin-right: auto;
    margin-left: auto;
    border-radius: 50px;
    margin-bottom: 10px;
    text-rendering: optimizeLegibility;
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    color: #fff;
    background-color: rgba(97, 61, 105, 0.5);
    border: none;
    cursor: pointer;
    height: 40px;
    margin-top: 50px;
  }
  button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }


}
</style>
<!-- src/components/ChatMessages.vue -->

<template>
  <div class="chat-messages" ref="chatContainer" @scroll="handleScroll">
    <div
      v-for="message in visibleMessages"
      :key="message.id"
      :class="[
        'message',
        message.senderId === userId ? 'my-message' : 'their-message',
        { unread: !message.is_read },
      ]"
    >
      <div class="message-content">
        {{ message.content }}
        <div v-if="message.attachment_url" class="attachment">
          <img
            v-if="isImage(message.attachment_url)"
            :src="getAuthenticatedUrl(message.attachment_url)"
            alt="Image Attachment"
            class="attachment-image"
            loading="lazy"
            @click="openFullScreen(message.attachment_url)"
            @contextmenu.prevent
            @mousedown.prevent
            @load="handleImageLoad"
          />
          <video
            v-if="isVideo(message.attachment_url)"
            controls
            class="attachment-video"
            loading="lazy"
          >
            <source
              :src="getAuthenticatedUrl(message.attachment_url)"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
          <audio
            v-if="isAudio(message.attachment_url)"
            controls
            class="attachment-audio"
            loading="lazy"
          >
            <source
              :src="getAuthenticatedUrl(message.attachment_url)"
              type="audio/mpeg"
            />
            Your browser does not support the audio tag.
          </audio>
          <a
            v-if="
              !isImage(message.attachment_url) &&
              !isVideo(message.attachment_url) &&
              !isAudio(message.attachment_url)
            "
            :href="getAuthenticatedUrl(message.attachment_url)"
            target="_blank"
            style="text-decoration: none; color: white"
            >{{ extractFileName(message.attachment_url) }}&nbsp;<i
              class="fas fa-download hover-icon"
            ></i
          ></a>
        </div>
      </div>
      <div v-if="isFullScreen" class="fullscreen-overlay">
        <div class="image-container">
          <img
            :src="fullScreenImageUrl"
            class="fullscreen-image"
            alt="Fullscreen media"
          />
          <button class="close-btn" @click="closeFullScreen">✕</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import SockJS from "sockjs-client";
// import Stomp from "webstomp-client";

export default {
  props: {
    conversationId: Number,
    userId: Number,
  },
  data() {
    return {
      visibleMessages: [],
      allMessages: [],
      messageBatchSize: 20,
      isLoading: false,
      reachedEnd: false,
      previousScrollHeight: 0,
      stompClient: null,
      lastReceivedMessageId: null,
      isFullScreen: false,
      fullScreenImageUrl: "",
    };
  },
  watch: {
    conversationId: {
      immediate: true,
      handler(newConversationId) {
        if (newConversationId) {
          this.reachedEnd = false;
          this.loadMessages(true);
        } else {
          this.visibleMessages = [];
          this.allMessages = [];
          if (this.stompClient) {
            this.stompClient.disconnect();
          }
        }
      },
    },
  },
  methods: {
    async loadMessages(initial = false) {
      if (this.isLoading || this.reachedEnd || !this.conversationId) return;

      this.isLoading = true;
      const offset = initial ? 0 : this.allMessages.length;

      try {
        const response = await axios.get(
          `/conversations/${this.conversationId}/messages`,
          {
            params: { offset, limit: this.messageBatchSize },
            withCredentials: true,
          }
        );
        console.log("Retrieved messages from backend:", response.data);

        const newMessages = response.data.reverse();
        if (newMessages.length < this.messageBatchSize) {
          this.reachedEnd = true;
        }

        // Ensure no duplicates are added to the message list
        const nonDuplicateMessages = newMessages.filter(
          (newMsg) =>
            !this.allMessages.some(
              (existingMsg) => existingMsg.id === newMsg.id
            )
        );

        if (initial) {
          this.allMessages = nonDuplicateMessages;
          this.visibleMessages = nonDuplicateMessages;
        } else {
          this.allMessages = [...nonDuplicateMessages, ...this.allMessages];
          this.visibleMessages = this.allMessages;
        }

        this.$nextTick(() => {
          if (initial) {
            this.scrollToBottom();
          } else {
            const chatContainer = this.$refs.chatContainer;
            if (chatContainer) {
              chatContainer.scrollTop =
                chatContainer.scrollHeight - this.previousScrollHeight;
            }
          }
        });
      } catch (error) {
        console.error("Error fetching messages:", error);
      } finally {
        this.isLoading = false;
      }
    },

    handleScroll() {
      const chatContainer = this.$refs.chatContainer;
      if (chatContainer && chatContainer.scrollTop === 0 && !this.isLoading) {
        this.previousScrollHeight = chatContainer.scrollHeight;
        this.loadMessages();
      }

      // Show scrollbar on scroll
    },
    scrollToBottom() {
      const chatContainer = this.$refs.chatContainer;
      if (chatContainer) {
        chatContainer.scrollTop = chatContainer.scrollHeight;
      }
    },
    updateMessages(newMessage) {
      // Check if the message already exists in allMessages
      const messageExists = this.allMessages.some(
        (message) => message.id === newMessage.id
      );

      if (!messageExists) {
        if (newMessage.conversationId === this.conversationId) {
          if (newMessage.attachmentUrl) {
            newMessage.attachment_url = newMessage.attachmentUrl;
            delete newMessage.attachmentUrl;
          }

          // Push the message only if it's for the current conversation
          this.allMessages.push(newMessage);
          this.visibleMessages = this.allMessages.slice(-this.messageBatchSize);
          this.$nextTick(() => this.scrollToBottom());
        } else {
          // If the message is for another conversation, update its unread count or other metadata
          this.handleUnreadCount(newMessage.conversationId);
        }
      }
    },

    handleUnreadCount(conversationId) {
      const conversation = this.$parent.conversations.find(
        (conv) => conv.id === conversationId
      );
      if (conversation) {
        // Increment unread count only if the conversation is not currently selected
        conversation.unread_count += 1;
        this.$parent.$forceUpdate(); // Update the parent component's state
      }
    },

    updateMessagesReadStatus() {
      this.visibleMessages.forEach((message) => {
        if (message.senderId !== this.userId) {
          message.is_read = true;
        }
      });
    },
    async markMessagesAsRead() {
      if (this.conversationId) {
        try {
          await axios.post(`/conversations/${this.conversationId}/read`, null, {
            withCredentials: true,
          });
          this.updateMessagesReadStatus();
        } catch (error) {
          console.error("Error marking messages as read:", error);
        }
      }
    },
    getAuthenticatedUrl(filename) {
      if (!filename) return "";

      const conversationId = this.conversationId;

      // Check if the URL is already a full URL or if it needs to be constructed
      if (filename.startsWith("http")) {
        return `${filename}${
          filename.includes("?") ? "&" : "?"
        }conversationId=${conversationId}`;
      }

      // Construct the URL for fetching the attachment from your backend
      return `${axios.defaults.baseURL}/uploads/conversation/${filename}?conversationId=${conversationId}`;
    },

    extractFileName(url) {
      try {
        const fullPath = url.split("?")[0]; // Remove the query string part
        const segments = fullPath.split("/"); // Split the URL by "/"
        return segments[segments.length - 1]; // Return the last segment (the filename)
      } catch (e) {
        return "Unknown file"; // Fallback if parsing fails
      }
    },

    isImage(url) {
      const cleanUrl = url.split("?")[0];
      return /\.(jpg|JPG|webp|jpeg|png|gif)$/.test(cleanUrl);
    },
    isVideo(url) {
      const cleanUrl = url.split("?")[0];
      return /\.(mp4|mov|avi|mkv|webm)$/.test(cleanUrl);
    },
    isAudio(url) {
      const cleanUrl = url.split("?")[0];
      return /\.(mp3|wav|m4a|ogg|weba)$/.test(cleanUrl);
    },

    openFullScreen(imageUrl) {
      this.fullScreenImageUrl = this.getAuthenticatedUrl(imageUrl);
      this.isFullScreen = true;
    },
    handleImageLoad(event) {
      const img = event.target;
      img.setAttribute("draggable", "false");
      img.setAttribute("oncontextmenu", "return false;");
    },
    closeFullScreen() {
      this.isFullScreen = false;
      this.fullScreenImageUrl = "";
    },
  },
  mounted() {
    this.markMessagesAsRead();
  },
  updated() {
    // Add logic to ensure media is processed when the component updates
    this.visibleMessages.forEach((message) => {
      if (message.attachmentUrl) {
        message.attachment_url = this.getAuthenticatedUrl(
          message.attachmentUrl
        );
      }
    });
  },
  beforeUnmount() {
    if (this.stompClient) {
      this.stompClient.disconnect();
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500&display=swap");

.chat-messages {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 10px;
  height: 100%;
  transition: all 0.3s ease-in-out;
}

.message {
  display: inline-block;
  min-width: 30%;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 15px;
  max-width: 60%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  font-size: 1rem;
  white-space: pre-wrap;
}

.my-message {
  text-align: right;
  font-family: "Roboto", sans-serif;
  align-self: flex-end;
  background-color: rgba(61, 41, 70, 1);
  color: white;
  border-bottom-right-radius: 0;
}

.attachment {
  margin-top: 10px;
  max-width: 100%;
}

.attachment-image {
  max-width: 100%;
  border-radius: 5px;
  max-height: 300px;
}

.attachment-video,
.attachment-audio {
  max-width: 100%;
  margin-top: 5px;
}

.attachment-audio {
  width: 100%;
  height: 40px;
  background-color: transparent;
  border-radius: 10px;

}

.attachment-video {
  max-width: 100%;
  height: 100%;
  background-color: #2e1d35;
  border-radius: 10px;

}

.their-message {
  font-family: "Roboto", sans-serif;
  text-align: left;
  align-self: flex-start;
  color: white;
  background-color: rgba(142, 100, 149, 1);
  border-bottom-left-radius: 0;
}

/** the style for scrollbar **/

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #2e1d35;
  border-radius: 10px !important;
}

::-webkit-scrollbar-thumb {
  background-color: #a07fa0;
  border-radius: 20px !important;
  border: 2px solid #2e1d35;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #a07fa0 #2e1d35;
}
/** the style for scrollbar **/

/** download icon **/

.hover-icon {
  transition: transform 0.3s ease, color 0.3s ease;
}

.hover-icon:hover {
  color: #d1c4e9;
  transform: scale(1.6);
  transition: all 0.3s ease;
}

.attachment-audio::-webkit-media-controls-panel {
  background-color: #a07fa0;
  border-radius: 10px;
}

.attachment-audio::-webkit-media-controls-play-button,
.attachment-audio::-webkit-media-controls-volume-slider {
  filter: invert(100%);
}

.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.fullscreen-image {
  width: 100%;
  height: 100dvh;
  object-fit: contain;
  border-radius: 10px;
}

.image-container {
  position: relative;
  max-width: 100%;
  max-height: 100%;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  transition: background 0.3s;
  width: 60px;
  height: 60px;
}

.close-btn:hover {
  background: rgba(0, 0, 0, 0.8);
}

/** download icon **/
</style>

<template>
  <div class="layout-container">
    <TopBar :profile="profileStore.profile" />
    <div class="line"></div>
    <div class="main-content">
      <LeftBar v-if="!isSmallScreen" :profile="profileStore.profile" />
      <div class="rightbar">
        <!-- Right sidebar and main content views -->
        <router-view name="rightbar" />
        <router-view /> <!-- Main content router view -->
      </div>
    </div>
  </div>
</template>

<script>
import TopBar from './TopBar.vue';
import LeftBar from './LeftBar.vue';
import { useProfileStore } from '@/store/profileStore';
import { onMounted, onUnmounted, ref } from 'vue';
import eventBus from "@/utils/eventBus";

export default {
  components: {
    TopBar,
    LeftBar,
  },
  setup() {
    const profileStore = useProfileStore();
    const isSmallScreen = ref(false);

    const checkScreenSize = () => {
      isSmallScreen.value = window.innerWidth <= 1100;
    };

    const refreshProfile = () => {
      profileStore.fetchProfile();
    };

    onMounted(() => {
      if (!profileStore.profile) {
        profileStore.fetchProfile();
      }

      // Listen for the profile update event
      eventBus.on('update-profile', refreshProfile);

      // Check screen size initially and on window resize
      checkScreenSize();
      window.addEventListener('resize', checkScreenSize);
    });

    onUnmounted(() => {
      // Clean up the event listener
      eventBus.off('update-profile', refreshProfile);
      window.removeEventListener('resize', checkScreenSize);
    });

    return {
      profileStore,
      isSmallScreen,
    };
  },
};
</script>

<style scoped>
.layout-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Opera */
}

.main-content {
  display: flex;
  flex: 1;
  overflow: hidden; /* Prevents the main content from creating scrollbars */
}


.rightbar {
  width: 70%;
  min-width: 800px;
  overflow: auto; /* Enable scrolling */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.rightbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.line {
  position: absolute;
  left: 0;
  margin-top: 90px;
  z-index: 10;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, #623e6a 0%, #b48bbb 27%, #bf9bc5 51.5%, #b48bbb 76%, #623e6a 100%);
  box-shadow: 0 1px 8px 0 rgb(201, 201, 201);
}

/* For screen widths 1100px and below, hide the LeftBar and adjust the RightBar to take full width */
@media screen and (max-width: 1100px) {
  .rightbar {
    width: 100%;
    min-width: 100%;
  }
}

/* For screen widths 768px and below */
@media screen and (max-width: 768px) {
  .layout-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    -webkit-user-select: none; /* Chrome/Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Opera */
  }


  .main-content {
    display: flex;
    flex: 1;
    overflow: hidden; /* Prevents the main content from creating scrollbars */
  }

  .rightbar {
    width: 100%;
    min-width: 100%;
    overflow: auto; /* Enable scrolling */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .rightbar::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .line {
    margin-top: 70px;
  }
}
</style>
